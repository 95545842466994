@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  overflow-x: hidden;
}

#viewerBackdrop button.arrow_12iocnn-o_O-arrow__direction__right_6eoou0-o_O-arrow__size__medium_5gm619 {
    display: none;
}

#viewerBackdrop button.arrow_12iocnn-o_O-arrow__direction__left_lg4jz7-o_O-arrow__size__medium_5gm619 {
  display: none;
}