.modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #ffffffb3;
    z-index: 99;
}

.form{
    width: 100%;
    height: auto;
    display: flex;
}

.formContainer {
    width: 100%;
    height: 100%;
    margin: 15px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 25px 30px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.formContainer button {
    width: 100%;
    max-width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #326dff;
    margin: auto;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: none;
}

.formContainer button:hover {
    background: rgba(50, 109, 255, .75);
}

.title{
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
}

.close{
    width: 35px;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #326dff;
    border-radius: 50%;
    cursor: pointer;
    background-image: url(../../../../assets/img/close.svg);
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
}

/* .citiesLabel {
    position: relative;
    margin-right: 20px;
} */

/* .citiesLabel::before {
    content: "";
    position: absolute;
    top: 11px;
    right: -11px;
    width: 10px;
    height: 20px;
    background-image: url(../../../../assets/img/more.svg);
    background-repeat: no-repeat;
    background-position: center;
    opacity: .5s;
} */

.citiesLabel > .MuiCheckbox-colorSecondary.Mui-checked{ 
    color: blue;
}

@media screen and (max-width: 650px) {
    .formContainer {
        margin: 0;
    }
  }
  